import payApi from "../api/payApi";

const payUtils = {
  wechatPay(orderId, payInfo){
    return new Promise((resolve, reject) => {
      window.payWeChatNotify = function (type) {
        window.payWeChatNotify = null;
        if (type == 0) {
          resolve();
          return;
        }
        reject(new Error(type));
      }
      // 请求后端
      payApi.wechatPay(orderId, payInfo).then(resposne => {
        const data = resposne.data;
        const wechatParams = {
          appId: data.appid,
          partnerId: data.mchid,
          prepayId: data.prepay_id,
          packageValue: 'WXPay',
          nonceStr: data.nonce,
          timestamp: data.timestamp,
          sign: data.sign
        }
        const jsonStr = JSON.stringify(wechatParams);
        window.android.payByWX(jsonStr);
      })
    })
  }
};


export default payUtils;
