<template>
    <div class="caibeivip">
        <div class="head">
            <div class="swiper-container" id="swiper1">
                <div class="swiper-wrapper" :style="btnchecck?'transition-duration: 300ms; transform: translate3d(0px, 0px, 0px);':'transition-duration: 300ms; transform: translate3d(-71px, 0px, 0px);'">
                    <div
                        class="swiper-slide"
                        v-for="(item, index) in configs"
                        :key="index"
                        @click="shousuo(index,item)"
                    >
                        <div class="headcontent">
                            
                            <p  class="moneytime" > <span>¥{{item.price}}</span> <span  class="money">有效期{{item.id===1?'7':'30'}}日</span></p>
                            <!--    <span class="money">有效期{{item.id===1?'7':'30'}}日</span> -->
                            <div v-if="item.vipStatus"  class="timeshi" :style="item.viewInfo.levelMark>0?'':'background: #F7C973;'"><div><p v-if="item.viewInfo" :class="(item.viewInfo.levelMark>0?'pcolor':null)">生效中</p><div  :style="item.viewInfo.levelMark>0?'':'color: rgba(157, 80, 19, 1);'" v-html="newtimeday(availableTime*1000,item.id)"></div></div> </div>
                            <img v-if="item.viewInfo" :src="item.viewInfo.bgImage" class="bookImg banner" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-for="(item,index) in configs" :key="index" :class="(item.viewInfo.levelMark>0?'bottomback':'')" :style="item.viewInfo.levelMark>0?!heights1?'margin-top:-10px;padding:0;':'':''">
                <div class="headcontent" @click="shousuo(index,item)">
                    <span class="money">¥{{item.price}}</span>
                    <div v-if="item.vipStatus"><p :class="(item.viewInfo.levelMark>0?'pcolor':null)">生效中</p><div v-html="newtimeday(availableTime*1000)"></div></div>
                    <div class="zankai" v-if="item.viewInfo.levelMark>0?!item.vipStatus?!heights:false:!item.vipStatus?!heights1:false">展开查看更多权益</div>
                    <img class="bgImage" :src="item.viewInfo.bgImage" alt="">
                </div>
            </div> -->
            <div class="bannercontent" v-if="vipitem">
                <img class="banner" src="https://ppyos.xijiuyou.com/202212/member-coin-top-banner.png" alt="" />
                <div class="contentvip">
                    <p class="title">{{vipitem.id===1?'VIP周卡权益':'VIP月卡权益'}}</p>
                    <div class="list">
                        <div v-for="(_item,_index) in vipitem.privileges" :key="_index" @click="golink(_item)">
                            <p :style="`background:url(${_item.icon}) no-repeat;background-size: 100%;`">
                                <img class="imgup" v-if="_item.mark" :src="_item.mark" alt="" />
                            </p>
                            <p>{{_item.title}}</p>
                            <p>{{_item.description}}</p>
                            <img class="zhenp" v-if="_item.isShowPrizeTags" src="https://ppyos.xijiuyou.com/202212/sign-conch-zs.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <img class="banner" @click="banners" :src="banner.bannerImg" alt=""> -->
        <div class="btnmoney"  @click="btnvip"><div  :style="hasVip?'background: #D8D8D8;color:#ffffff;':''"><span v-if="!hasVip">¥{{vipitem.price}}</span>{{ hasVip?'已购买':'立即购买' }}</div></div>

        <van-action-sheet v-model="show" title="VIP购买确认">
            <div class="content">
                <div class="contentshow">
                    <div><p>商品</p><div class="kapian" v-if="vipitem">{{vipitem.title}}</div></div>
                    <div><p>商品说明</p><div  v-if="vipitem">{{vipitem.note}}</div></div>
                    <div><p>支付方式</p><div>微信支付</div></div>
                </div>
                <div class="contentbtn">
                    <p>实际支付：<span>¥</span><span  v-if="vipitem">{{vipitem.price}}</span></p>
                    <div @click="paymoney">立即支付</div>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>
<script>
import Chest from "../api/Chest";
import payUtils from "../utils/payUtil";
import { Toast } from "vant";
import game from "../api/game";
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import "swiper/dist/js/swiper.min";
export default{
    data(){
        return {
            heights:false,
            show:false,
            heights1:true,
            configs:[],
            availableTime:0,
            vipitem:{},
            hasVip:true,
            banner:{},
            paymoneys:true,
            btnchecck:false,
        }
    },
    mounted(){
        this.conchCoinVipCardList();
        this.bannerfind();
        const that = this;
        new Swiper("#swiper1", {
            // slidesPerView: "auto",
             //centeredSlides: true,
            //loop: true,
            // slideToClickedSlide:true,
            observer: true,
            slidesPerView: "auto",
            spaceBetween: 12,
            on: {
                reachEnd: function(){
                    that.vipitem = that.configs[1];
                //你的事件
                },
                reachBeginning: function(){
                //你的事件
                that.vipitem = that.configs[0];
                },
            },
        });
    },
    methods:{
        btnvip(){
            if(!this.hasVip){
                Toast("因业务调整，VIP周卡月卡暂停购买，已购买开通的VIP服务可继续使用，感谢您的参与");
                //  this.show=true;
            }else{
                this.show=false;
            }
        },
        golink(item){
            if(item.href){
                window.location.href = item.href;
            }
        },
        banners(){
            try {
            window.android.createNewWeb(
                this.banner.link
            );
            } catch (e) {
                Toast("您当前app版本过低请更新app");
            }
        },
        bannerfind(){
            game.bannerfind(12).then(res=>{
                this.banner = res.data[0]
            })
        },
        shousuo(index,item){
            console.log(1)
            if(index == 1){
                this.btnchecck = false;
                // if(this.heights){
                //     return false;
                // }
                // this.heights = !this.heights;
                // if(this.heights){
                //     this.heights1 = false;
                // }
            }else{
                this.btnchecck = true;
                // if(this.heights1){
                //     return false;
                // }
                // this.heights1 = !this.heights1;
                // if(this.heights1){
                //     this.heights = false;
                // }
            }
            this.vipitem =item;
        },
        paymoney(){
            if(!this.paymoneys){
                return false;
            }
            try {
                if(window.android.getUserID() == 23947 || window.android.getUserID() == 565653){
                     Toast("该功能暂时不开放，敬请期待");    
                     return;
                }
            }catch(error){
                console.log("唤起支付失败，请重试")
            }

            this.paymoneys = false;
            const that = this;
            Chest.createOrder(that.vipitem.id).then(res=>{
                payUtils
                .wechatPay(res.data.orderNum,res.data.payInfoService)
                .then(() => {
                    this.paymoneys = true;
                    const time = setTimeout(()=>{
                        that.conchCoinVipCardList();
                        clearTimeout(time);
                        this.show = false;
                        Toast("彩贝会员已生效");
                    },500)
                })
                .catch(() => {
                    Toast("支付失败");
                    this.paymoneys = true;
                });
            })
            
        },
        newtimeday(time,id){
            if(id === 1){
                if(parseInt(time/1000/3600/24)>0){
                    return  `剩 <span style="font-size: 16px;font-family: DINOffcPro-Bold, DINOffcPro;font-weight: bold;color: rgb(157, 80, 19);margin: 4px 2px 0;"> ${parseInt(time/1000/3600/24)}</span> 日 <span style="font-size: 16px;font-family: DINOffcPro-Bold, DINOffcPro;font-weight: bold;color: rgb(157, 80, 19);margin: 4px 2px 0;">${parseInt((time/1000/3600)%24)}</span> 小时`
                }else{
                    // return `剩余 ${parseInt((time/1000/3600)%24)} 小时`
                    if(parseInt((time/1000/3600)%24) >= 1){
                        return `剩 <span style="font-size: 16px;font-family: DINOffcPro-Bold, DINOffcPro;font-weight: bold;color: rgb(157, 80, 19);margin: 4px 2px 0;">${parseInt((time/1000/3600)%24)}</span> 小时`
                    }else{
                        return `剩 &lt; <span style="font-size: 16px;font-family: DINOffcPro-Bold, DINOffcPro;font-weight: bold;color: rgb(157, 80, 19);margin: 4px 2px 0;">1</span> 小时`;
                    }
                }
            }else{
                if(parseInt(time/1000/3600/24)>0){
                    return  `剩 <span style="font-size: 16px;font-family: DINOffcPro-Bold, DINOffcPro;font-weight: bold;color: #FFFFFF;margin: 4px 2px 0;"> ${parseInt(time/1000/3600/24)}</span> 日 <span style="font-size: 16px;font-family: DINOffcPro-Bold, DINOffcPro;font-weight: bold;color: #FFFFFF;margin: 4px 2px 0;">${parseInt((time/1000/3600)%24)}</span> 小时`
                }else{
                    // return `剩余 ${parseInt((time/1000/3600)%24)} 小时`
                    if(parseInt((time/1000/3600)%24) >= 1){
                        return `剩 <span style="font-size: 16px;font-family: DINOffcPro-Bold, DINOffcPro;font-weight: bold;color: #FFFFFF;margin: 4px 2px 0;">${parseInt((time/1000/3600)%24)}</span> 小时`
                    }else{
                        return `剩 &lt; <span style="font-size: 16px;font-family: DINOffcPro-Bold, DINOffcPro;font-weight: bold;color: #FFFFFF;margin: 4px 2px 0;">1</span> 小时`;
                    }
                }
            }
            
        },
        conchCoinVipCardList(){
            Chest.conchCoinVipCardList().then(res=>{
                this.configs = res.data.configs;
                res.data.configs.map(_res=>{
                    if(_res.viewInfo.levelMark == 0 &&  _res.vipStatus){
                        this.heights =false;
                        this.heights1 =true;
                    }else if(_res.viewInfo.levelMark == 1 &&  _res.vipStatus){
                        this.heights =true;
                        this.heights1 =false;
                    }
                })
                this.availableTime = res.data.availableTime;
                this.vipitem = res.data.configs[1];
                this.hasVip = res.data.hasVip;
            })
        },
    }
}
</script>
<style lang="less" scoped>
.caibeivip{
    width: 100%;
    min-height: 100vh;
    background: #ffffff;
    padding-bottom: 100px;
    text-align: center;
    .head{
        width: 100%;
        background:  linear-gradient(180deg, #C0CDE2 0%, #ffffff 100%);;
        padding-top: 15px;
        .headcontent{
            width: 203px;
            height: 132px;
            margin: 0 auto;
            position: relative;
            // margin-left: -20px;
            .moneytime{
                display: flex;
                width: 100%;
                padding: 0 15px;
                justify-content: space-between;
                position: absolute;
                top: 75px;
                font-size: 28px;
                font-family: DINOffcPro-Bold, DINOffcPro;
                font-weight: bold;
                color: #FFFFFF;
                align-items: center;
                z-index: 1;
                .money{
                    font-size: 12px!important;
                    font-family: PingFangSC-Regular, PingFang SC!important;
                    font-weight: 400!important;
                    color: rgba(0,0,0,0.6)!important;
                    background: rgba(255,255,255,0.5);
                    border-radius: 9px;
                    backdrop-filter: blur(3px);
                    padding: 0 5px;
                    height: 18px;
                    line-height: 18px;
                }
                span{
                    display: inline-block;
                    font-size: 28px;
                    font-family: DINOffcPro-Bold, DINOffcPro;
                    font-weight: bold;
                    color: #FFFFFF;
                    z-index: 1;
                    
                }
            }
            .bookImg{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
            }
            .zankai{
                width: calc(100% - 24px);
                height: 22px;
                background: rgba(255,255,255,0.26);
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                border-radius: 11px;
                justify-content: center;
                margin-top: 3px;
                position: relative;
                z-index: 1;
            }
            .timeshi{
                display: flex;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                align-items: center;
                padding:0 8px 0 5px;
                position: absolute;
                justify-content: space-between;
                align-items: center;
                top:-10px;
                z-index: 1;
                background: linear-gradient(270deg, #49C5FF 0%, #B75FFF 100%);
                border-radius: 5px 5px 5px 2px;
                // width: 155px;
                height: 24px;
                .money{
                    font-size: 12px!important;
                    font-family: PingFangSC-Regular, PingFang SC!important;
                    font-weight: 400!important;
                    color: #3F4E6F!important;
                    background: rgba(255,255,255,0.5);
                    border-radius: 9px;
                    backdrop-filter: blur(3px);
                    padding: 0 13px;
                    height: 18px;
                    line-height: 18px;
                    display: inline-block;
                }
                div{
                    display: flex;
                    align-items: center;
                }
                div div{
                    margin:0;
                    // position: relative;
                    // top:0;
                    width: auto;
                }
                .pcolor{
                    background: linear-gradient(270deg, #541AFF 0%, #7C00E5 100%);
                    color: #fff;
                }
                div p{
                    padding: 0 2px;
                    height: 18px;
                    background: RGBA(182, 94, 23, 1);
                    border-radius: 5px;
                    font-size: 12px;
                    font-family: AlimamaShuHeiTi-Bold, AlimamaShuHeiTi;
                    font-weight: 600;
                    color: #ffffff;
                    text-align: center;
                    margin-right: 9px;
                    line-height: 18px;
                }
            }
        }
        .contentvip{
            width: 100%;
            height: 430px;
            position: relative;
            z-index: 1;
            margin-top: -35px;
            background: #FFFFFF;
            padding-top:5px;
            
            .title{
                font-size: 16px;
                font-family: AlimamaShuHeiTi-Bold, AlimamaShuHeiTi;
                font-weight: bold;
                color: #333333; 
                text-align: center;
                margin-top: 5px;
            }
        }
        .list{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 5px;
            margin-top: 14px;
            div{
                width: 32%;
                // height: 130px;
                background: linear-gradient(180deg, rgba(241, 219, 183, 0.15) 0%, rgba(248, 233, 206, 0.15) 100%);
                border-radius: 10px;
                margin-bottom: 9px;
                text-align: center;
                padding: 18px 10px;
                position: relative;
                img{
                    width: 40px;
                    height: 40px;
                }
                .zhenp{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 24px;
                    height: 24px;
                }
                p:nth-child(1){
                    width: 40px;
                    height: 40px;
                    margin: 0 auto;
                    position: relative;
                    .imgup{
                        width: 24px;
                        height: 17px;
                        position: absolute;
                        right: -8px;
                        bottom:  0px;
                    }
                }
                p:nth-child(2){
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: bold;
                    color: #333333;
                    margin: 10px 0 0px;
                }
                p:nth-child(3){
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }
    }
    .bottomback{
        padding-top: 10px;
        position: relative;
        z-index: 1;
    }
    .btnmoney{
        width: 100%;
        height: 68px;
        background: #FFFFFF;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 2;
        padding: 0 11px;
        padding-top: 10px;
        div{
            width: 100%;
            height: 48px;
            background: linear-gradient(270deg, #9A42FF 0%, #FF497A 100%);
            border-radius: 24px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            span{
                font-size: 22px;
                font-family: DINOffcPro-Bold, DINOffcPro;
                font-weight: bold;
                color: #FFFFFF;
                margin-right: 4px;
            }
        }
    }
    .content{
        background: rgba(246, 246, 246, 1);
        padding-top: 30px;
        .contentshow{
            div{
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                padding: 0 14px;
                margin-bottom: 30px;
                .kapian{
                    font-size: 20px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }
                div{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333; 
                    margin: 0;
                    padding: 0;
                }
            }
        }
        .contentbtn{
            width: 100%;
            height: 68px;
            background: #FFFFFF;
            display: flex;
            justify-content: space-between;
            padding: 0 12px;
            align-items: center;
            p{
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                display: flex;
                align-items: center;
                line-height: 68px;
                span:nth-child(1){
                    font-size: 16px;
                    font-family: DINOffcPro-Bold, DINOffcPro;
                    font-weight: bold;
                    color: #FE487A;
                    margin:0 2px 0 2px;
                }
                span:nth-child(2){
                    font-size: 32px;
                    font-family: DINOffcPro-Medi, DINOffcPro;
                    font-weight: normal;
                    color: #FE487A;
                }
            }
            div{
                width: 148px;
                height: 48px;
                background: linear-gradient(270deg, #9A42FF 0%, #FF497A 100%);
                border-radius: 24px;
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                text-align: center;
                line-height: 48px;
            }
        }
    }
    // .banner{
    //     width: calc(100% - 22px);
    //     margin: 20px 0 0;
    // }
}
.bannercontent{
    position: relative;
    margin-top:35px;
    .banner{
    width: 100%;
    position: absolute;
    top:-9px;
    left: 0;
    z-index: 100;
  }
}
.van-popup{
    background: rgba(246, 246, 246, 1);
}
// .bookImg {
//   width: 85vw!important;
// }
.swiper-slide{
    width: 203px!important;
}
.swiper-container{
    padding: 10px 14px 0 14px;
    margin-top: -10px;
}
</style>