import axios from "axios";

const payApi = {
  wechatPay(orderId, payInfo){
    let wechatService = "wechatPayService"
    try{
      let pgName = window.android.getPackageName();
      if(pgName === "com.jiuyou.caibeiwan") {
        wechatService = "wechatPayCbwService"
      }
    }catch(ignored){
      console.log(ignored)
    }
    return axios.post(`/api/ppy/usercenter/pay/request/${wechatService}`,null,{
      params: {
        orderId,
        payInfo
      }
    })
  }
}

export default payApi;
